import {  Row, Col} from 'react-bootstrap';
import { ResumeList } from './resumeList';
export const ResumeSection = (props)=>{


    return (
        <>
      <Row><Col lg={10} className={"text-left"} ><strong>{props.title}</strong> </Col></Row>
      <Row><Col lg={10} className={"text-left"} ><strong>{props.location}</strong> </Col><Col >{props.timeframe}</Col></Row>
      <Row><Col  className={"text-left"} >{props.description}</Col></Row>
      <ResumeList items={props.skills} title="Skills" bold={true}></ResumeList>
      <ResumeList items={props.accomplishments} title="Selected Accomplishments"></ResumeList>
        <hr></hr>
        </>
    )
}