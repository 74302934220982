
import { ListGroup,Card } from "react-bootstrap";
import { Item } from './listItem.js';
import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'

export const SelectList = (props)=>{


    //console.log("PROPS IN CAT LIST" + JSON.stringify(props));
    const listHeader = (props.isAvailableList)?"Available Subjects":"Selected Subjects";
    const listDescription = (props.isAvailableList)?"These are a list of Subjects availble for Michael Grecol":"The Resume Below reflects these subjects";
    const listInstructions = (props.isAvailableList)?"Drag items to the Selected Subjects box to see what Michael has to offer in this area":"Drag items back to the Available Subjects box to remove them.";
    const thisCurrentList = (props.isAvailableList)? props.lists.availableList:props.lists.selectedList;
    const otherCurrentList = (props.isAvailableList)?props.lists.selectedList:props.lists.availableList;
   // console.log(thisCurrentList);
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.BOX,
        drop:  (item,monitor) => {
          if(!thisCurrentList.includes(item.name)){
            thisCurrentList.push(item.name);
          }
            const index = otherCurrentList.indexOf(item.name);
            if(index>=0){
            otherCurrentList.splice(index,1);
            props.updateProc(props.lists.selectedList);
            }
            return (
            { name: 'Dustbin' })
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      }))
      const isActive = canDrop && isOver
      let backgroundColor = '#fff'
      if (isActive) {
        backgroundColor = 'green'
      } else if (canDrop) {
        backgroundColor = 'lightGrey'
      }

//props.thisList.push("You know");


const categories = ()=>{  
  ///  console.log("props" + props.thisList);
const cats = [];
const thisCurrentList = (props.isAvailableList)? props.lists.availableList:props.lists.selectedList;
for(var i =0; i<thisCurrentList.length; i++){
    const nm = thisCurrentList[i];
    cats.push(<Item name={nm} key={nm}></Item>);
    
}
return cats;
}
    return (
        <Card ref={drop} data-testid="dustbin" style={{backgroundColor:backgroundColor}} className={"p-1 p-sm-1 p-md-2 p-lg-2"}>
      <Card.Header className={"p-1 p-sm-1 p-md-2 p-lg-2"}>{listHeader}</Card.Header>
      <Card.Body className={"p-1 p-sm-1 p-md-2 p-lg-2"}>
        <Card.Title className="text-dark p-1 p-sm-1 p-md-2 p-lg-2" >{listDescription}</Card.Title>
        <Card.Text className="text-dark p-1 p-sm-1 p-md-2 p-lg-2" >
          {listInstructions}
        </Card.Text>
<ListGroup >
    {categories()}
</ListGroup>
</Card.Body>
</Card>

    )
}