export const ResumeList = (props)=>{
    
    const listItems = ()=>{
        const out = [];
        if(props.items.length<1){
        }else{
           
            props.items.forEach((s,i)=>{
                if(props.bold){
                    out.push(<strong key={i} ><li >{s} </li></strong>);
                }else{
                    out.push(<li key={i}>{s} </li>);
                }
               
            });
        }
       return out;
    };
    const display = props.items.length>0?"":"none";

return (
<div style={{display:display}}>
<h4 className={"text-left"}>{props.title}</h4>
<ul className={"text-left"} >
{listItems()}
</ul>
</div>
);
}