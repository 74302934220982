
import './App.css';
import { Container, Row, Col, Carousel, Image } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { BrowserView, MobileView } from 'react-device-detect';
import { SelectList } from './selectList';

import axios from 'axios';
import { ResumeList } from './resumeList';
import { ResumeSection } from './resumeSection';

function App() {

  const [lists, setLists] = useState({
    availableList: [],
    selectedList: []

  }
  );
  const [resume, setResume] = useState({
    summarySkills: [],
    summaryDescription: "",
    disneyDescription: "",
    disneySkills: [],
    disneyAccomplishments: [],
    hondaDescription: "",
    hondaSkills: [],
    hondaAccomplishments: [],
    thermaDescription: "",
    thermaSkills: [],
    thermaAccomplishments: [],
    transDescription: "",
    transSkills: [],
    transAccomplishments: [],
    nnrDescription: "",
    nnrSkills: [],
    nnrAccomplishments: [],
    showResume: false
  });
  const updateResume = (selected) => {
    if (selected.length > 0) {
      let query = "";
      selected.forEach((s, i) => {
        query = query + ((i > 0) ? " " : "") + s;
      });
      //console.log(query);
      axios.get("https://resumeservice.mgrecol.com/resumeContent?keyword=" + encodeURIComponent(query)).then(
        (response) => {
          //console.log(response.data);
          const { summarySkills,
            summaryDescription,
            disneyDescription,
            disneySkills,
            disneyAccomplishments,
            hondaDescription,
            hondaSkills,
            hondaAccomplishments,
            thermaDescription,
            thermaSkills,
            thermaAccomplishments,
            transDescription,
            transSkills,
            transAccomplishments,
            nnrDescription,
            nnrSkills,
            nnrAccomplishments } = response.data;
          const showResume = (summaryDescription.length > 0 || summarySkills.length > 0) ? true : false;
          setResume({
            summarySkills,
            summaryDescription,
            disneyDescription,
            disneySkills,
            disneyAccomplishments,
            hondaDescription,
            hondaSkills,
            hondaAccomplishments,
            thermaDescription,
            thermaSkills,
            thermaAccomplishments,
            transDescription,
            transSkills,
            transAccomplishments,
            nnrDescription,
            nnrSkills,
            nnrAccomplishments, showResume
          });
        }
      );
    } else {
      const { summarySkills,
        summaryDescription,
        disneyDescription,
        disneySkills,
        disneyAccomplishments,
        hondaDescription,
        hondaSkills,
        hondaAccomplishments,
        thermaDescription,
        thermaSkills,
        thermaAccomplishments,
        transDescription,
        transSkills,
        transAccomplishments,
        nnrDescription,
        nnrSkills,
        nnrAccomplishments } = resume;
      const showResume = false;
      setResume({
        summarySkills,
        summaryDescription,
        disneyDescription,
        disneySkills,
        disneyAccomplishments,
        hondaDescription,
        hondaSkills,
        hondaAccomplishments,
        thermaDescription,
        thermaSkills,
        thermaAccomplishments,
        transDescription,
        transSkills,
        transAccomplishments,
        nnrDescription,
        nnrSkills,
        nnrAccomplishments, showResume
      });
    }
  };
  const gotCategory = useRef(false);
  useEffect(() => {
    if (!gotCategory.current) {
      gotCategory.current = true;
      axios.get("https://resumeservice.mgrecol.com/resumeCategory").then((response) => {
        // console.log(response.data);
        setLists((currentLists) => {
          // console.log("Current List " + currentLists.availableList);
          while (currentLists.availableList.length > 0) {
            currentLists.availableList.pop();
          }
          while (currentLists.selectedList.length > 0) {
            currentLists.selectedList.pop();
          }
          response.data.forEach(element => {
            currentLists.availableList.push(element);
          });
          const { availableList, selectedList } = currentLists;
          return ({ availableList, selectedList });
        });
      });
    }
  }, [lists.selectedList, lists.availableList]);

  const printer = () => {
    window.print();
  }

  return (
    <div className="App">

      <Container className={"d-print-none sb-0 mb-0 lb-3"} >

        <Row><Col><h1>Welcome to the Michael Grecol Resume Page.</h1></Col></Row>
        <Carousel>
          <Carousel.Item interval={2000}>
            <Image src={"matrix-356024_1280.webp"} height={900} />
            <Carousel.Caption>
              <h3>Software Engineering</h3>
              <p>A driving force in Softare Engineering for several large companies.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <Image src={"airplane-5655710_1280.jpg"} height={900} />
            <Carousel.Caption>
              <h3>Aerospace</h3>
              <p>Aerospace enthusiast and Private Pilot.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <Image src={"family-run-business-645614_1280.jpg"} height={900} />
            <Carousel.Caption>
              <h3>Manufacturing Systems and Process Engineering</h3>
              <p>
                Champion of process design and manufacturing systems.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <Image src={"lighthouse-2611199_1280.jpg"} height={900} />
            <Carousel.Caption>
              <h3>Supply Chain and Trade</h3>
              <p>
                Deep experience and success in supply chain and trade management.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <Image src={"business-4778044_1280.jpg"} height={900} />
            <Carousel.Caption>
              <h3>Leadership</h3>
              <p>
                Manager and Leader of technical and non technical teams.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <br></br>
        <h3>To learn more about Michael's skills and achievements, use the resume builder tool below: </h3>
        <BrowserView>
          <DndProvider backend={HTML5Backend}>

            <Row>
              <Col>
                <SelectList lists={lists} isAvailableList={true} updateProc={updateResume}></SelectList>

              </Col>
              <Col><SelectList lists={lists} isAvailableList={false} updateProc={updateResume} ></SelectList>
              </Col>
            </Row>
          </DndProvider>

        </BrowserView>
        <MobileView>
          <DndProvider backend={TouchBackend}>

            <Row>
              <Col>
                <SelectList lists={lists} isAvailableList={true} updateProc={updateResume}></SelectList>

              </Col>
              <Col><SelectList lists={lists} isAvailableList={false} updateProc={updateResume} ></SelectList>
              </Col>
            </Row>
          </DndProvider>
        </MobileView>

      </Container>
      <br></br>
      <br></br>
      <Container style={{ display: (resume.showResume) ? "" : "none", backgroundColor: "white" }} className="text-dark">
        <input type="button" className={"d-print-none btn btn-success"} value="Print Resume" onClick={printer}></input>
        <h2>Michael L. Grecol</h2>
        <h4>14327 Wabasso Loop, Winter Garden, FL 34787</h4>
        <h4>937-303-0283 | mike1@mgrecol.com</h4>

        <h3 className={"text-left"}>Summary</h3>
        <Row><Col className={"text-left"} >{resume.summaryDescription}</Col></Row>
        <ResumeList items={resume.summarySkills} title="Skills"></ResumeList>

        <h3 className={"text-left"}>Work Experience</h3>
        <ResumeSection
          title={"Senior Manager Systems and Analytics"}
          location={"Disney Worldwide Services | Orlando FL "}
          timeframe={"2018-Present"}
          description={resume.disneyDescription}
          skills={resume.disneySkills}
          accomplishments={resume.disneyAccomplishments}
        ></ResumeSection>
        <ResumeSection
          title={"Engineering Coordinator"}
          location={"Honda of Americal Manufacturing | Marysville OH"}
          timeframe={"2009-2018"}
          description={resume.hondaDescription}
          skills={resume.hondaSkills}
          accomplishments={resume.hondaAccomplishments}
        ></ResumeSection>
        <ResumeSection
          title={"Trade Compliance Manager"}
          location={"Therma Tru Doors | Toledo OH "}
          timeframe={"2008-2009"}
          description={resume.thermaDescription}
          skills={resume.thermaSkills}
          accomplishments={resume.thermaAccomplishments}
        ></ResumeSection>
        <ResumeSection
          title={"International Operations Manager/Corporate Air Operations Manager"}
          location={"Transgroup | Seattle, Houston"}
          timeframe={"2005-2008"}
          description={resume.transDescription}
          skills={resume.transSkills}
          accomplishments={resume.transAccomplishments}
        ></ResumeSection>
        <ResumeSection
          title={"User Support Analyst/Import Manager"}
          location={"NNR Global Logistics | Cleveland, Chicago, Seattle"}
          timeframe={"2000-2005"}
          description={resume.nnrDescription}
          skills={resume.nnrSkills}
          accomplishments={resume.nnrAccomplishments}
        ></ResumeSection>
        <h3 className={"text-left"}>Education</h3>
        <ResumeSection
          title={"Georgia Southern Univeristy"}
          location={"Master of Science | Computer Science 4.0/4.0"}
          timeframe={""} description={""} skills={[]} accomplishments={[]}  ></ResumeSection>
        <ResumeSection
          title={"Bellevue University"}
          location={"Bacheolor of Science | Logistics Management 4.0/4.3"}
          timeframe={""} description={""} skills={[]} accomplishments={[]}  ></ResumeSection>
        <ResumeSection
          title={"ETI Technical College"}
          location={"Associate of Science | Electronic Engineering"}
          timeframe={""} description={""} skills={[]} accomplishments={[]}  ></ResumeSection>
      </Container>


    </div>

  );
}

export default App;
