import { ListGroup} from "react-bootstrap";
import { useDrag } from 'react-dnd'
import { ItemTypes } from "./ItemTypes";
const style = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'move',
    float: 'left',
  }
export const Item = function Item ({name}){
  //console.log("Item Props "+ JSON.stringify(name));
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: { name},
        end: (item, monitor) => {
          //  console.log("Draging "+ name);
          const dropResult = monitor.getDropResult()
          if (item && dropResult) {
           // alert(`You dropped ${item.name} into ${dropResult.name}!`)
          }
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
          handlerId: monitor.getHandlerId(),
        }),
      }))

      const opacity = isDragging ? 0.4 : 1


return(
    <ListGroup.Item ref={drag} style={{opacity }} data-testid={`box`} className={"p-1 p-sm-1 p-md-2 p-lg-3"} >     {name} </ListGroup.Item>




)
}